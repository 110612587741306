<ng-template #modal>
  <div class="modal-content" [ngStyle]="{'width': modalConfig.width}">
  <div class="modal-header" [ngClass]="{'border-bottom': header === 'true'}" *ngIf="modalConfig.modalTitle">
    <h5 class="modal-title">{{ modalConfig.modalTitle }}</h5>
    <button type="button" class="btn-close" (click)="close()"></button>
  </div>
  <div class="modal-body" [ngClass]="{'text-left': header === 'true'}" [style.width]="modalConfig.width">
    <ng-content></ng-content>
  </div>
  <ng-container *ngIf="!modalConfig.hideDismissButton || !modalConfig.hideCloseButton">
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="dismiss()" *ngIf="!modalConfig.hideDismissButton"
        [disabled]="modalConfig.disableDismissButton">
        {{ modalConfig.dismissButtonLabel }}
      </button>
      <button type="button" class="btn btn-outline-primary" (click)="close()" *ngIf="!modalConfig.hideCloseButton"
        [disabled]="modalConfig.disableCloseButton">
        {{ modalConfig.closeButtonLabel }}
      </button>
    </div>
  </ng-container>
</div>
</ng-template>